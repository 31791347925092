@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&family=Roboto:wght@300;500;700&display=swap');

body {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.app {
  background-color: #0d0d0d;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.container {
  width: 100%;
  max-width: 1100px;
  color: #e6cf9f;
  display: flex;
  flex-direction: column;
  padding-inline: 20px;
}

.upper {
  display: flex;
  height: 22vh;
}
.text,
.link {
  flex: 1;
}

.link {
  text-align: right;
  display: flex;
  justify-content: left;
}

.link p {
  font-size: clamp(14px, 2vw, 22px);
  font-weight: 300;
  width: 21ch;
  cursor: pointer;
}

.text {
  text-align: right;
  display: flex;
  justify-content: right;
}

.text p {
  font-size: clamp(14px, 2vw, 22px);
  font-weight: 300;
  width: 21ch;
}
.line {
  width: 3px;
  height: 100%;
  background-color: #e6cf9f;
  margin-inline: clamp(10px, 1vw, 30px);
}

.bottom {
  display: flex;
  align-items: flex-end;
  height: 22vh;
}

.test2 {
  display: flex;
  justify-content: flex-start;
}
.test2 p {
  text-align: left;
  width: 6ch;
}
.test1 p {
  width: 10ch;
}

.middle {
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.middle h1 {
  font-weight: 300;
  font-size: clamp(36px, 30vw, 90px);
  margin-block: 10px;
}

/* 888888888888888 */
.header {
  display: flex;
  justify-content: space-between;
}
.names {
  display: flex;
  align-items: center;
  margin-left: -20px;
}

.bride {
  text-align: right;
  width: 10ch;
  font-weight: 300;
}
.groom {
  text-align: left;
  width: 10ch;
  font-weight: 300;
}

.middle-line {
  width: 15px;
  height: 15px;
  border: 1px solid #e6cf9f;
  border-radius: 50%;
  position: relative;
  margin-inline: clamp(5px, 1vw, 15px);
}

.small-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  background-color: #e6cf9f;
  border-radius: 50%;
  transform: translate(-40%, -40%);
}

.title h2 {
  font-size: clamp(22px, 3vw, 38px);
  position: relative;
  display: inline-block;
}
.title h2::after {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 2px;
  background-color: red;
}

.main {
  height: 100%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.main-img {
  display: flex;
  align-items: center;
  height: 100%;
}
.main-img img {
  height: 80%;
  max-height: 450px;
}
.main-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.main-contianer {
  margin-block: 20px;
}
.main h2 {
  font-weight: 400;
  margin: 0;
}
.main h3 {
  font-weight: 300;
  margin: 0;
  max-width: 40ch;
}
.footer {
  display: flex;
  justify-content: flex-end;
}

.footer-text {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-block: 20px;
}

.footer .line {
  margin-inline: 5px;
}
.footer h3 {
  font-weight: 28px;
  margin: 5px;
  font-weight: 300;
}

@media only screen and (max-width: 500px) {
  .main-img {
    display: none;
  }
}
